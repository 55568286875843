@import 'styles/generic';
@import 'styles/base';
@import 'styles/trumps';
@import 'styles/appSpinner';

// @@@Amine global vars needed for theme changing
:root {
  --main-primary-color: #01071c;
  --main-bg-color: #031030;
  --menu-item-selected: #0d5bd9;
  --layout-trigger-background: #01061d;
  --menu-submenu-bg: #01061d;
  --text-color: #333333;
}

// @@@Amine override class to add the global variables

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--main-primary-color)
}
a{
  color: var(--main-primary-color);
}

.ant-layout-sider {
  background: var(--main-bg-color);
}
.ant-layout-sider-trigger {
  background: var(--layout-trigger-background);
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--main-bg-color);
}

.ant-btn-primary {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}
.ant-tabs-top > .ant-tabs-nav{
  margin: 0;
}
.ant-tabs-tabpane{
  margin: 0;
}
