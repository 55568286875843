@import 'settings';
@import 'tools';

.ant-collapse-no-content {
  .ant-collapse-content {
    display: none;
  }
}
.connector-item .ant-form-item-label {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.connector-item .ant-form-item-label label {
  flex: 1;
}

.connector-item.ant-form-item-has-error .ant-form-item-label {
  margin-bottom: 24px;
}

//.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
//  margin-top: 8px;
//}
//.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
//  background-color: rgb(200, 200, 200);
//  color: rgb(178, 178, 178);
//  border-color: rgb(214, 214, 214);
//  margin-top: 8px;
//}
//.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
//  color: rgb(178, 178, 178);
//}

//octo-select-card__btn-active
.octo-select-card__btn-active {
  color: #40a9ff;
  border-color: #40a9ff;
  border: 1px solid #40a9ff !important;
}
.octo-select-card__btn-disabled {
  color: #D4D8DE;
  background: #F5F6F8 !important;
  border: 1px solid #D4D8DE !important;
  cursor: default;
}
.octo-select-card__container {
  width: 300px;
  height: 120px;
  padding: 24px;
  background: white;
  border: 2px solid #e8e9ef;
  cursor: pointer;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@module: table;
