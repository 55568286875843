// General
@import 'codemirror/lib/codemirror.css';

// Addons
@import 'codemirror/addon/hint/show-hint.css';
@import 'codemirror/addon/lint/lint.css';
@import 'codemirror/addon/fold/foldgutter.css';

// Theme
@import 'codemirror/theme/yeti.css';

body {
  background: #eee;
}

.container {
  margin: 10px auto;
  display: block;
  max-width: 780px;
}

.lint-error {
  font-family: sans-serif;
  font-size: 70%;
  background: #ffa;
  color: #a00;
  padding: 2px 5px 3px;
}

.lint-error-icon {
  color: white;
  background-color: red;
  font-weight: bold;
  border-radius: 50%;
  padding: 0 3px;
  margin-right: 7px;
}

.tables {
  display: flex;
  justify-content: center;
}

.table {
  margin: 0 10px;
}

.table * {
  font-family: sans-serif;
  cursor: pointer;
}
