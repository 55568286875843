@import 'settings';
@import 'tools';

.ant-list-sm .ant-list-item {
  padding: 0 16px;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-divider-horizontal {
  margin: 10px 0;
}
@module: explorer;
