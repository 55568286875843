.config-menu {
  .ant-menu-item {
    padding: 0 16px 0 16px;
  }
}

.configuration-menu__settings-container {
  margin: 4px 0 8px;
  padding: 0 16px;
}
.configuration-menu__settings-menu-content {
  width: 100%;
  height: auto;
  padding: 4px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .configuration-menu__settings-menu-content-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }
}
