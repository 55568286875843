html,
body {
  font-family: 'Chinese Quote', Helvetica, Arial, sans-serif;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  width: auto;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}
