@import 'settings';
@import 'tools';

@module: applayout;

:root {
  --main-text-color: #444;
  --main-select-input-child-button: #3c3fee;
}
.site-layout__main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background-color: #f5f6fa;
}
.site-layout__slider {
  position: relative;
  height: 100vh;
  transition: all 0.2s;
}
.site-layout__fixed-slider {
  position: fixed;
  height: 100vh;
  .ant-layout-sider-trigger {
    background-color: var(--layout-trigger-background);
  }
}

.site-layout__logo-container {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.site-layout__right-layout {
  width: 100%;
  transition: all 0.2s;
  .site-layout__right-layout-content {
    background: #f5f6fa;
    display: flex;
    flex-direction: column;
    min-height: 95vh;
  }
  .site-layout__right-layout-footer {
    padding: 5px;
    background: #f5f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.octolis__main-container {
  padding: 24px;
}
.octolis__columns .octolis__column {
  display: flex;
}
.octolis__is-width-one-fifth {
  width: 20% !important;
}
.octolis__is-aligned-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.octolis__is-overflow-hidden {
  overflow: hidden;
}
.octolis__default-padding {
  padding: 24px;
}
.octolis__default-padding-left-right {
  padding: 0 24px;
}
.octolis__default-padding-left {
  padding-left: 24px;
}

.octolis__default-margin {
  margin: 24px;
}
.octolis__default-margin-top-bot {
  margin: 24px 0;
}
.octolis__default-margin-top {
  margin-top: 24px;
}

.octolis__default-margin-left {
  margin-left: 24px;
}
.octolis__default-margin-left-auto {
  margin-left: auto;
}
.octolis__default-margin-bottom {
  margin-bottom: 24px;
}

.octolis__default-margin-top-5 {
  margin-top: 5px;
}
.octolis__margin-bottom-34 {
  margin-bottom: 34px;
}
.octolis__default-alert {
  max-height: 32px;
}
.octolis__cursor-pointer {
  cursor: pointer;
}
.octolis__text-bold {
  font-weight: bold;
}

.octolis__default-background-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.octolis__default-logo-expanded {
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
}

.octolis__default-logo {
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
}
.octolis__default-border-radius {
  border-radius: 6px;
}
.octolis__main-h2 {
  color: var(--main-text-color);
  font-weight: bold;
}
.octolis__main-h3 {
  margin: 4px;
  color: var(--main-text-color);
}
.octolis__main-h4 {
  color: var(--main-text-color);
  font-weight: bold;
}
.octolis__main-active-color {
  color: var(--main-select-input-child-button);
}
.octolis__justify-content-flex-end {
  justify-content: flex-end;
}

.octolis__select-child-button {
  padding-right: 0;
  color: var(--main-select-input-child-button);
}
.octolis__text-align-right {
  text-align: right;
}
.octolis__float-right {
  float: right;
}
.octolis__text-align-left {
  text-align: left;
}
.octolis__default-btn {
  color: #0097a7;
  background-color: white;
  text-decoration-line: none;
  padding: 0 30px;
  border: 2px solid #0097a7;
  border-radius: 6px;
  &:hover,
  &:active,
  &:focus {
    color: #f1f1f1;
    background-color: #0097a7;
  }
}
.octolis__primary-btn {
  color: #f1f1f1;
  background-color: #0097a7;
  text-decoration-line: none;
  padding: 0 30px;
  border: 2px solid #0097a7;
  border-radius: 6px;
  &:hover,
  &:active,
  &:focus {
    color: #f1f1f1;
    background-color: #0097a7;
  }
}
.octolis__secondary-btn {
  background-color: #e2e2e9;
  color: #595959;
  text-decoration-line: none;
  padding: 0 30px;
  border: 2px solid #e2e2e9;
  border-radius: 6px;
  &:hover,
  &:active,
  &:focus {
    background-color: #e2e2e9;
    color: #595959;
  }
}
.octolis__danger-btn {
  color: #ea2c2c;
  background-color: white;
  text-decoration-line: none;
  padding: 0 30px;
  border: 1px solid #ea2c2c;
  border-radius: 6px;
  &:hover,
  &:active,
  &:focus {
    color: #f1f1f1;
    background-color: #ea2c2c;
  }
}
.octolis__display-flex {
  display: flex;
}
.octolis__full_width {
  width: 100%;
}
.octolis__display-flex-direction-column {
  flex-direction: column;
}
.octolis__display-flex-center {
  align-items: center;
  justify-content: center;
}
.octolis__disabled-container {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.site-layout__slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  ul:last-of-type {
    margin-top: auto;
    margin-bottom: 60px;
    width: 100%;
  }
  //overrides
  .ant-menu-item {
    padding: 0 16px !important;
  }
}
.sider-menu-item-selected {
  border: #565656;
  color: #ffffff !important;
  background-color: rgba(64, 64, 64, 0.5) !important;
  border-radius: 8px;
}

.sider-menu-item-button {
  width: 100%;
  text-align: left;
  color: #ffffff;
}
.sider-menu-item-button:hover {
  color: #ffffff;
  background-color: rgba(224, 224, 224, 0.2) !important;
  border-radius: 8px;
}

// overrides
.ant-layout-sider-trigger {
  width: 250px !important;
}
.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}
.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
  .ant-layout-sider-trigger {
    width: 80px !important;
  }

  .sider-menu-item-button {
    width: 100%;
    text-align: left;
    color: #ffffff;
  }
}
.ant-layout-sider-collapsed .site-layout {
  background: #fff;
  margin-left: 8% !important;
}

.ant-menu-item-selected {
  color: #ffffff;
  background-color: transparent !important;
}

.ant-drawer-content {
  background-color: #f5f6fa;
}

.ant-modal-body {
  background-color: #f5f6fa;
}
.ant-modal-header {
  background: #f5f6fa !important;
}
.ant-modal-footer {
  background: #f5f6fa !important;
}
// Override  antD popover css
.ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  padding: 4px 1px;
}
.ant-popover-inner-content-custom-padding {
  .ant-popover-inner-content {
    padding: 12px 16px;
  }
}
.business-terms-modal-container {
  .ant-modal-body {
    padding-bottom: 0px;
  }
}
// Override border radius
.ant-popover-inner {
  border-radius: 6px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input,
.ant-picker {
  border-radius: 6px;
}

.ant-select-selection-placeholder {
  margin-left: 10px;
}
